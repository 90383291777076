import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { useState } from 'react'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, isNull, orderBy } from 'lodash'
import { convertDateFromString } from '../../utils/date/convertDateFromString'
import Spinner from '../../ui/Spinner/Spinner'
import './EduTiles.sass'
import { formatDateAsDayMonthNameYear } from '../../utils/date/dateFormat'
import Button from '../../ui/Button/Button'
import { NavLink } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'

function EduTiles({ title, docPath, tilePath }) {
  const [data, setData] = useState(null)

  useEffect(() => {
    getCollection({ path: docPath, docIdName: 'id' }).then((res) =>
      setData(
        orderBy(
          res.map((r) => {
            r.eventDate = convertDateFromString(r.date)
            return r
          }),
          ['eventDate'],
          ['desc']
        )
      )
    )
  }, [])

  return (
    <div className="EduTiles">
      <div className="BreadCrumbs">
        <span>
          <NavLink
            activeClassName="BreadCrumbs_Link_active"
            exact
            to={'/osvitni-programi'}
          >
            Освітні програми
          </NavLink>
        </span>
        <span>
          <Icon name="angle-right" />
        </span>
        <span>
          <NavLink
            activeClassName="BreadCrumbs_Link_active"
            exact
            to={tilePath}
          >
            {title}
          </NavLink>
        </span>
      </div>
      <SectionTitle title={title} />
      <div className="EduTiles-Container">
        {!isEmpty(data) ? (
          data.map((d) => <EduTile path={tilePath} {...d} />)
        ) : isNull(data) ? (
          <Spinner type="module" />
        ) : (
          <p className="EmptyContent">Немає записів</p>
        )}
      </div>
    </div>
  )
}

function EduTile({ id, title, photos, date, path }) {
  const avatar = find(photos, ['isAvatar', true])
    ? find(photos, ['isAvatar', true]).publicUrl
    : null

  return (
    <div className="EduTile">
      <img src={avatar} alt={title} />
      <div className="Content">
        <p className="Title">{title}</p>
        <div className="EventDate">Дата проведення: {date}</div>
        <Button
          theme="primary-white"
          title="Детальніше"
          type="navlink"
          path={`${path}/${id}`}
        />
      </div>
    </div>
  )
}

export default EduTiles
