import React, { useEffect } from 'react'
import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import getDoc from '../../../utils/db/getDoc'
import parse from 'html-react-parser'
import Spinner from '../../../ui/Spinner/Spinner'
import { isEmpty } from 'lodash'
import './ServicesPage.sass'

function ServicesPage({ ...router }) {
  const categoryId = router.match.params.id
  const [data, setData] = useState(null)

  useEffect(() => {
    if (categoryId) {
      getDoc({
        path: 'settings/price/categories',
        docId: categoryId,
        docIdName: 'categoryId',
      }).then((res) => setData(res))
    }
  }, [categoryId])

  return (
    <div id="text" className="ServicesPage">
      {!isEmpty(data) ? (
        <>
          <p className="Title">{data.title}</p>
          <div className="Body">
            {parse(data.description)}
            {!isEmpty(data.photos) && (
              <div className="Photos">
                {data.photos.map((p) => (
                  <img src={p.publicUrl} alt="hz" />
                ))}
              </div>
            )}
          </div>
        </>
      ) : (
        <Spinner type="module" />
      )}
    </div>
  )
}

export default withRouter(ServicesPage)
