import React from 'react'
import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import './SitePage.sass'
import Top from '../../components/Top/Top'
import ServicesNav from '../Services/ServicesNav/ServicesNav'

function SitePage({ children }) {
  return (
    <>
      <Top />
      <Nav />
      <div className="SitePage">
        <div className="SitePage-Container DefaultContainer ">
          <ServicesNav />
          {children}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SitePage
