import React from 'react'
import './Footer.sass'
import Logo from '../../ui/Logo/Logo'
import SocialLinks from '../Top/SocialLinks/SocialLinks'
import PhoneLinks from '../Top/PhoneLinks/PhoneLinks'
import EmailLink from '../Top/EmailLink/EmailLink'
import { Link, NavLink } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import Partners from '../Partners/Partners'

function Footer() {
  return (
    <footer id="contacts" className="Footer">
      <div className="Main DefaultContainer">
        <Logo navLink path={'/#top'} />
        <div className="ContactBlock">
          {/* <EmailLink /> */}
          <PhoneLinks />
        </div>
      </div>
      <div className="Footer-InfoContainer DefaultContainer">
        <div className="Clinic">
          <div className="LinkBlock">
            <a
              href="https://firebasestorage.googleapis.com/v0/b/renmed-f7ef9.appspot.com/o/pdf%2Flicense.jpg?alt=media"
              target="blank"
            >
              Ліцензія АЕ 638762
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/renmed-f7ef9.appspot.com/o/pdf%2FpublicDoc.pdf?alt=media"
              target="blank"
            >
              Публічний договір
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/renmed-f7ef9.appspot.com/o/pdf%2Fpersonal.pdf?alt=media"
              target="blank"
            >
              Правила внутрішнього розпорядку
            </a>
          </div>
          <div className="LinkBlock">
            <NavLink to="/akcii">Акції</NavLink>
            <NavLink to="/nashi-speczialisti">Наші спеціалісти</NavLink>
            <NavLink to="/obladnannya">Обладнання</NavLink>
          </div>
          <div className="LinkBlock">
            <NavLink to="/naukova-diyalnist">Наукова діяльність</NavLink>
            <NavLink to="/osvitni-programi">Освітні программи</NavLink>
          </div>
        </div>
        <div className="Contacts">
          <a
            href="https://www.google.com/maps?q=Ренесанс+Медікал,+Фонтанська+дорога,+31,+Одеса,+Одеська+область,+65000&ftid=0x40c631bd0e97ba91:0xac0cd2f6d039cbc2&entry=gps&lucs=,47071704,47084304&g_ep=CAISDDYuOTcuMC4zNjAyMBgBIKzfASoSLDQ3MDcxNzA0LDQ3MDg0MzA0QgJVQQ%3D%3D&g_st=ic"
            target="blank"
            className="Address"
          >
            <Icon name="map-marker-alt" weight="solid" />
            <span>м. Одеса, Фонтанська дорога 31</span>
          </a>
          <div className="BusyHours">
            <p>Понеділок - Неділя: 09:00 - 20:00</p>
          </div>
          <SocialLinks />
        </div>
      </div>
      <div className="Line" />
      <div className="Copyrights DefaultContainer">
        © 2023 Всі права захищено. САМОЛІКУВАННЯ МОЖЕ БУТИ ШКІДЛИВИМ ДЛЯ ВАШОГО
        ЗДОРОВ'Я
      </div>
    </footer>
  )
}

export default Footer
