import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyAjW9NN6DJBt_wGZ12azRUqKXZu0Blum6s',
  authDomain: 'renmed-f7ef9.firebaseapp.com',
  projectId: 'renmed-f7ef9',
  storageBucket: 'renmed-f7ef9.appspot.com',
  messagingSenderId: '82971595664',
  appId: '1:82971595664:web:732ebd562cf28d26725915',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
