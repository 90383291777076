import React, { useEffect, createContext, useState } from 'react'
import { Route } from 'react-router-dom'
import Home from './pages/Home/Home'
import SitePage from './pages/SitePage/SitePage'
import { firebase } from './config/firebase'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import CookieNotification from './components/CookieNotification/CookieNotification'
// import TourPage from './components/TourPage/TourPage'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import getDoc from './utils/db/getDoc'
import Services from './pages/Services/Services'
import TextPage from './pages/TextPage/TextPage'
import Scientific from './pages/Scientific/Scientific'
import { isEmpty } from 'lodash'
import Contacts from './pages/Contacts/Contacts'
import Doctors from './components/Doctors/Doctors'
import ServicesPage from './pages/Services/ServicesPage/ServicesPage'
import Equipment from './pages/Equipment/Equipment'
import Actions from './pages/Actions/Actions'
import Edu from './pages/Edu/Edu'
import EduTiles from './pages/EduTiles/EduTiles'
import EduPage from './pages/EduPage/EduPage'

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  // const [user, setUser] = useState(null)
  const [showContactUs, setShowContactUs] = useState({
    show: false,
    title: '',
    emailSubject: '',
  })
  // const [lang, setLang] = useState(localStorage.getItem('lang') || 'ru')
  const [contacts, setContacts] = useState(null)

  useEffect(() => {
    getDoc({ path: 'pages', docId: 'contacts' }).then((data) =>
      setContacts(data)
    )
  }, [])

  // useEffect(() => {
  //   const userStateListener = firebase.auth().onAuthStateChanged(setUser)
  //   return () => {
  //     userStateListener()
  //   }
  // }, [])

  return !isEmpty(contacts) ? (
    <AuthContext.Provider value={{ contacts, showContactUs, setShowContactUs }}>
      {children}
    </AuthContext.Provider>
  ) : null
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <SitePage>
        <Route path="/" exact component={Home} />
        <Route path="/services/:id" exact component={ServicesPage} />
        {/* <Route
          path="/laser-vision-correction"
          exact
          render={() => <TextPage docId={'laser'} />}
        />
        <Route
          path="/about-optics"
          exact
          render={() => <TextPage docId={'optics'} />}
        />
        <Route
          path="/scientific-activity"
          exact
          render={() => <Scientific title="Наукова діяльність" path="nauk" />}
        />
        <Route
          path="/mass-media"
          exact
          render={() => <Scientific title="ЗМІ" path="smi" />}
        /> */}
        <Route path="/nashi-speczialisti" exact render={() => <Doctors />} />
        {/* <Route path="/contacts" exact render={() => <Contacts />} /> */}
        <Route path="/obladnannya" exact render={() => <Equipment />} />
        <Route path="/akcii" exact render={() => <Actions />} />
        <Route path="/osvitni-programi" exact render={() => <Edu />} />
        <Route
          path="/osvitni-programi/treningi"
          exact
          render={() => (
            <EduTiles
              title="Тренінги"
              docPath={'traning'}
              tilePath="/osvitni-programi/treningi"
            />
          )}
        />
        <Route
          path="/osvitni-programi/treningi/:id"
          exact
          render={() => (
            <EduPage
              parentPath="/osvitni-programi/treningi"
              title="Тренінги"
              docPath={'traning'}
            />
          )}
        />
        <Route
          path="/osvitni-programi/naukovi-konferencii"
          exact
          render={() => (
            <EduTiles
              title="Конференції"
              docPath={'konf'}
              tilePath="/osvitni-programi/naukovi-konferencii"
            />
          )}
        />
        <Route
          path="/osvitni-programi/naukovi-konferencii/:id"
          exact
          render={() => (
            <EduPage
              parentPath="/osvitni-programi/naukovi-konferencii"
              title="Конференції"
              docPath={'konf'}
            />
          )}
        />
        <Route
          path="/osvitni-programi/inshi-programmy"
          exact
          render={() => (
            <EduTiles
              title="Інші програми"
              docPath={'inshe'}
              tilePath="/osvitni-programi/inshi-programmy"
            />
          )}
        />
        <Route
          path="/osvitni-programi/inshi-programmy/:id"
          exact
          render={() => (
            <EduPage
              title="Інші програми"
              parentPath="/osvitni-programi/inshi-programmy"
              docPath={'inshe'}
            />
          )}
        />
        <Route
          path="/naukova-diyalnist"
          exact
          render={() => <TextPage title="Наукова діяльність" docId={'nauk'} />}
        />
        {/* <Route path="/project/:id" exact component={ProjectPage} />
        <Route
          path={['/manage-project', '/manage-project/:id']}
          exact
          component={ManageProjectPage}
        />
        <Route path="/admin" exact component={Auth} /> */}
      </SitePage>
      <CookieNotification />
    </AuthProvider>
  )
}

export default App
