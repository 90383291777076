import React, { useContext } from 'react'
import InfoLine from '../../ui/InfoLine/InfoLine'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './About.sass'
import aboutImg from './about.jpg'
import Button from '../../ui/Button/Button'
import ReactPlayer from 'react-player'
import light from './light.png'
import bgImage from './consultPattern.svg'
import { useEffect } from 'react'
import getDoc from '../../utils/db/getDoc'
import { useState } from 'react'
import { find, isEmpty } from 'lodash'
import parse from 'html-react-parser'
import { AuthContext } from '../../App'
import promiseAllValues from '../../utils/promiseAllValues'
import getCollection from '../../utils/db/getCollection'

function About() {
  const [data, setData] = useState(null)
  const { contacts, setShowContactUs } = useContext(AuthContext)
  useEffect(() => {
    promiseAllValues({
      page: getDoc({
        path: 'pages',
        docId: 'about',
        docIdName: 'aboutPage',
      }),
      categories: getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
        whereQueries: [{ fieldPath: 'isHero', op: '==', value: true }],
      }),
      purpose: getDoc({
        path: 'pages',
        docId: 'purpose',
        docIdName: 'purposeId',
      }),
    }).then((res) => setData(res))
  }, [])

  return (
    <section
      id="about"
      name="about"
      class="About"
      // style={{ backgroundImage: `url(${bgImage})` }}
    >
      {!isEmpty(data) && (
        <>
          <div class="About-Text">
            {parse(data.page.text)}
            {/*
              <Button
                theme="primary-white"
                title="Записатись на консультацію"
                type="button"
                onClick={() =>
                  setShowContactUs({
                    show: true,
                    title: 'Записатись на консультацію',
                    emailSubject: 'Запит на консультацію',
                  })
                }
                // onClick={() => (window.location.href = `tel:${contacts.phone}`)}
              /> */}
            <div className="Hero-Categories">
              {data.categories.map((c) => {
                const avatar = find(c.photos, ['isAvatar', true])
                return (
                  <div className="CategoryTile">
                    <img src={avatar ? avatar.publicUrl : ''} alt={c.title} />
                    <div className="Content">
                      <p className="Title">{c.title}</p>
                      <Button
                        theme="primary-white"
                        title="Детальніше"
                        type="navlink"
                        path={`/services/${c.categoryId}`}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="PurposeText">{parse(data.purpose.text)}</div>
        </>
      )}
    </section>
  )
}

export default About
