import React from 'react'
import './Hero.sass'
import { NavLink, withRouter } from 'react-router-dom'
import parse from 'html-react-parser'
import { NavHashLink } from 'react-router-hash-link'
import Icon from '../../ui/Icon/Icon'
import hero1 from './assets/1.jpg'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import Button from '../../ui/Button/Button'
import location from '../../locale/location'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import { find, isEmpty } from 'lodash'
import { useState } from 'react'
import { useEffect } from 'react'
import getDoc from '../../utils/db/getDoc'
import Carousel from '../../ui/Carousel/Carousel'
import Media from 'react-media'

function Hero() {
  const [data, setData] = useState(null)
  useEffect(() => {
    getDoc({
      path: 'slider',
      docId: 'homeSlider',
    }).then((res) => setData(res))
  }, [])

  return (
    <div id="home" className="Site-Hero">
      <div className="SiteHero-Container">
        <Media
          query="(min-width: 769px)"
          render={() =>
            !isEmpty(data) && (
              <Carousel
                element="Hero-Carousel"
                options={{
                  type: 'carousel',
                  perView: 1,
                  autoplay: 5000,
                  useDots: true,
                  useArrows: true,
                }}
              >
                {/* .filter((p, i) => i % 2 === 0) */}
                {data.photos.map((p, i) => {
                  return (
                    <a href={`/akcii#${i}`} target="blank">
                      <img src={p.publicUrl} alt="hero-slider" />
                    </a>
                  )
                })}
              </Carousel>
            )
          }
        />
        <Media
          query="(max-width: 768px)"
          render={() =>
            !isEmpty(data) && (
              <Carousel
                element="Hero-Carousel"
                options={{
                  type: 'carousel',
                  perView: 1,
                  autoplay: 5000,
                  useDots: true,
                  useArrows: true,
                }}
              >
                {/* .filter((p, i) => i % 2 !== 0) */}
                {data.photos.map((p, i) => {
                  return (
                    <a href={`/akcii#${i}`} target="blank">
                      <img src={p.publicUrl} alt="hero-slider" />
                    </a>
                  )
                })}
              </Carousel>
            )
          }
        />
      </div>
    </div>
  )
}

export default withRouter(Hero)
