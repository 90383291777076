import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Doctors.sass'
import { useState } from 'react'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, orderBy, toNumber } from 'lodash'
import parse from 'html-react-parser'
import Icon from '../../ui/Icon/Icon'
import toggleSpoiler from '../../utils/toggleSpoiler'

function Doctors() {
  const [doctors, setDoctors] = useState(null)
  const [openDoctor, setOpenDoctor] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'doctors',
      docIdName: 'doctorId',
      whereQueries: [{ fieldPath: 'isDeleted', op: '!=', value: true }],
    }).then((docs) => setDoctors(orderBy(docs, ['pos'], ['asc'])))
  }, [])

  return (
    <section id="doctors" name="doctors" class="Doctors">
      <SectionTitle title="Лікарі клініки Ренесанс Медікал" />
      <div class="Doctors-Container">
        {!isEmpty(doctors) &&
          doctors.map((d) => (
            <DoctorTile
              isActive={openDoctor === d.doctorId}
              setOpenDoctor={() =>
                openDoctor === d.doctorId
                  ? setOpenDoctor(null)
                  : setOpenDoctor(d.doctorId)
              }
              key={d.doctorId}
              {...d}
            />
          ))}
      </div>
    </section>
  )
}

function DoctorTile({
  isActive,
  setOpenDoctor,
  title,
  photos,
  descriptionShort,
  descriptionLong,
  startingYear,
  langs,
}) {
  const avatar = find(photos, ['isAvatar', true])
    ? find(photos, ['isAvatar', true]).publicUrl
    : null
  const stage = new Date().getFullYear() - toNumber(startingYear)

  return (
    <div className={`DoctorTile`}>
      {/* <PopUp show={showPopUp} title={title} close={() => setShowPopUp(false)}>
        <div className="DescriptionContent">
          {parse(descriptionLong)}
          <Button
            className="CloseDoctorDescription"
            theme="primary-white"
            title="Закрити"
            type="button"
            onClick={() => setShowPopUp(false)}
          />
        </div>
      </PopUp> */}
      <div className="Avatar">
        {avatar ? <img src={avatar} alt={title} /> : <Icon name="user-md" />}
      </div>
      <div className="Content">
        <div className="Title">{title}</div>
        <div className="ShortDesc">{descriptionShort}</div>
        <div className={`Details  ${isActive && 'Spoiler_status_expanded'}`}>
          <div className="Header" onClick={setOpenDoctor}>
            Детальніше <Icon name="angle-down" />
          </div>
          <div className="Body">{parse(descriptionLong)}</div>
        </div>
        <p className="Langs">Знання мов: {langs}</p>
      </div>
    </div>
  )
}

export default Doctors
