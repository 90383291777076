import React from 'react'
import './SocialLinks.sass'
import Icon from '../../../ui/Icon/Icon'
import { Link } from 'react-router-dom'
import Media from 'react-media'

function SocialLinks() {
  return (
    <div className="SocialLinks">
      <Media
        query="(max-width: 768px)"
        render={() => <span className="Title">Ми у соц. мережах:</span>}
      />

      <a href="https://www.facebook.com/renaissancemedical.ua" target="blank">
        <Icon name="facebook-f" weight="brand" />
      </a>
      <a href="https://instagram.com/renaissance_medical.ua" target="blank">
        <Icon name="instagram" weight="brand" />
      </a>
      <a href="https://www.tiktok.com/@renaissance_medical" target="blank">
        <Icon name="tiktok" weight="brand" />
      </a>
    </div>
  )
}

export default SocialLinks
