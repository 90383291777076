import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Button from '../../ui/Button/Button'
import './Edu.sass'
import t1 from './t1.jpg'
import t2 from './t2.jpg'
import t3 from './t3.jpg'

function Edu() {
  return (
    <div className="Edu">
      <SectionTitle title="Освітні програми" />
      <div className="Hero-Categories">
        <CatTile
          title="Конференції"
          src={t1}
          path="/osvitni-programi/naukovi-konferencii"
        />
        <CatTile title="Тренінги" src={t2} path="/osvitni-programi/treningi" />
        <CatTile
          title="Інші програми"
          src={t3}
          path="/osvitni-programi/inshi-programmy"
        />
      </div>
    </div>
  )
}

function CatTile({ title, path, src }) {
  return (
    <div className="CategoryTile">
      <img src={src} alt={title} />
      <div className="Content">
        <p className="Title">{title}</p>
        <Button
          theme="primary-white"
          title="Детальніше"
          type="navlink"
          path={path}
        />
      </div>
    </div>
  )
}

export default Edu
