import React, { useEffect, useState } from 'react'
import getCollection from '../../../utils/db/getCollection'
import Spinner from '../../../ui/Spinner/Spinner'
import { isEmpty, orderBy } from 'lodash'
import './ServicesNav.sass'
import Icon from '../../../ui/Icon/Icon'
import toggleSpoiler from '../../../utils/toggleSpoiler'
import { NavLink } from 'react-router-dom'
import Media from 'react-media'
import { HashLink, NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../../ui/ScrollToTop/scrollWithOffset'

function ServicesNav() {
  const [categories, setCategories] = useState(null)
  const [openCat, setOpenCat] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'settings/price/categories',
      docIdName: 'categoryId',
    }).then((res) =>
      setCategories(
        orderBy(
          res.filter((r) => !r.isDeleted && true),
          ['pos'],
          ['asc']
        )
      )
    )
  }, [])

  return (
    <div className="ServicesNav">
      <div className="Title" onClick={toggleSpoiler}>
        Наші послуги
        <Media
          query="(max-width: 1200px)"
          render={() => <Icon name="angle-down" />}
        />
      </div>
      <div className="ServicesNav-Body">
        {!isEmpty(categories)
          ? categories
              .filter((c) => !c.parentCat && true)
              .map((c) => (
                <ServiceSpoiler
                  categoryId={c.categoryId}
                  title={c.title}
                  isActive={openCat === c.categoryId}
                  setOpenCat={() =>
                    openCat === c.categoryId
                      ? setOpenCat(null)
                      : setOpenCat(c.categoryId)
                  }
                  subCategories={categories.filter(
                    (cat) => cat.parentCat === c.categoryId
                  )}
                />
              ))
          : Spinner}
      </div>
    </div>
  )
}

function ServiceSpoiler({
  isActive,
  categoryId,
  title,
  subCategories,
  setOpenCat,
}) {
  return (
    <div className={`ServiceSpoiler ${isActive && 'Spoiler_status_expanded'}`}>
      <HashLink
        to={`/services/${categoryId}#text`}
        className="Header"
        onClick={setOpenCat}
        scroll={(el) => scrollWithOffset(el, -64)}
      >
        {title} {!isEmpty(subCategories) && <Icon name="angle-down" />}
      </HashLink>
      <div className="Body">
        {subCategories.map((sc) => (
          <HashLink
            to={`/services/${sc.categoryId}#text`}
            className="SubCategory"
            smooth
            scroll={(el) => scrollWithOffset(el, -64)}
          >
            {sc.title}
          </HashLink>
        ))}
      </div>
    </div>
  )
}

export default ServicesNav
