import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './PhoneLinks.sass'
import { useContext } from 'react'
import { AuthContext } from '../../../App'

function PhoneLinks() {
  const { contacts } = useContext(AuthContext)
  return (
    <div className="PhoneLinks">
      {/* <a href={`tel: ${contacts.phone.split(',')[0]}`} className="PhoneIcon">
        <Icon name="phone" weight="solid" />
      </a> */}
      {contacts.phone.split(',').map((p) => (
        <a href={`tel: ${p}`} className="PhoneNumber ">
          {p}
        </a>
      ))}
      <div className="MesLinks">
        <a href="viber://chat?number=%2B380997189000" target="blank">
          <Icon name="viber" weight="brand" />
        </a>
        <a href="https://t.me/+380997189000" target="blank">
          <Icon name="telegram" weight="brand" />
        </a>
        <a
          href="https://api.whatsapp.com/send/?phone=380997189000"
          target="blank"
        >
          <Icon name="whatsapp" weight="brand" />
        </a>
      </div>
    </div>
  )
}

export default PhoneLinks
