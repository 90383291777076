import React, { useEffect, useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Equipment.sass'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, orderBy } from 'lodash'
import Spinner from '../../ui/Spinner/Spinner'

function Equipment() {
  const [data, setData] = useState(null)

  useEffect(() => {
    getCollection({ path: 'instruments', docIdName: 'eId' }).then((res) =>
      setData(orderBy(res, ['pos'], ['asc']))
    )
  }, [])

  return (
    <div className="Equipment">
      <SectionTitle title="Обладнання" />
      <div className="Equipment-Container">
        {!isEmpty(data) ? (
          data.map((d) => <EquipmentTile key={d.eId} {...d} />)
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </div>
  )
}

function EquipmentTile({ photos, title, descriptionShort, descriptionLong }) {
  const avatar = find(photos, ['isAvatar', true])
    ? find(photos, ['isAvatar', true]).publicUrl
    : null
  return (
    <div className="EquipmentTile">
      <img src={avatar} alt={title} />
      <div className="DescriptionShort">{descriptionShort}</div>
      <p className="Title">{title}</p>
      <div className="DescriptionLong">{descriptionLong}</div>
    </div>
  )
}

export default Equipment
